import React from 'react';
import loadable from '@loadable/component';

const AccordionV1 = loadable(() => import('./AccordionV1'));
const ChildrenOnly = loadable(() => import('./ChildrenOnly'));

const Accordion = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'children-only':
      return <ChildrenOnly {...props} />;
    default:
    case 'version1':
      return <AccordionV1 {...props} />;
  }
};

export default Accordion;
